var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-card", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _vm._v("gin-vue-admin")
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 8, offset: 8 } }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://github.com/flipped-aurora/gin-vue-admin"
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "org-img dom-center",
                                attrs: {
                                  src: require("@/assets/logo.png"),
                                  alt: "gin-vue-admin"
                                }
                              })
                            ]
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://github.com/flipped-aurora/gin-vue-admin"
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "dom-center",
                                attrs: {
                                  src:
                                    "https://img.shields.io/github/watchers/flipped-aurora/gin-vue-admin.svg?label=Watch",
                                  alt: ""
                                }
                              })
                            ]
                          )
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://github.com/flipped-aurora/gin-vue-admin"
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "dom-center",
                                attrs: {
                                  src:
                                    "https://img.shields.io/github/stars/flipped-aurora/gin-vue-admin.svg?style=social",
                                  alt: ""
                                }
                              })
                            ]
                          )
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://github.com/flipped-aurora/gin-vue-admin"
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "dom-center",
                                attrs: {
                                  src:
                                    "https://img.shields.io/github/forks/flipped-aurora/gin-vue-admin.svg?label=Fork",
                                  alt: ""
                                }
                              })
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("el-card", { staticStyle: { "margin-top": "20px" } }, [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _vm._v("flipped-aurora团队")
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 8, offset: 8 } }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://github.com/flipped-aurora"
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "org-img dom-center",
                                attrs: {
                                  src: require("@/assets/flipped-aurora.png"),
                                  alt: "flipped-aurora"
                                }
                              })
                            ]
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      {
                        staticStyle: { "margin-left": "40px" },
                        attrs: { gutter: 20 }
                      },
                      [
                        _vm._l(_vm.members, function(item, index) {
                          return [
                            _c("el-col", { key: index, attrs: { span: 8 } }, [
                              _c("a", { attrs: { href: item.html_url } }, [
                                _c("img", {
                                  staticClass: "avatar-img",
                                  attrs: { src: item.avatar_url }
                                }),
                                _c("a", { staticClass: "author-name" }, [
                                  _vm._v(_vm._s(item.login))
                                ])
                              ])
                            ])
                          ]
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _vm._v(" 提交记录 ")
                  ]),
                  _c(
                    "div",
                    [
                      _c("Timeline", {
                        attrs: {
                          "timeline-items": _vm.dataTimeline,
                          "message-when-no-items": _vm.messageWhenNoItems,
                          uniqueTimeline: true,
                          "unique-year": true,
                          "show-day-and-month": true,
                          order: "desc",
                          dateLocale: "zh-CN"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "load-more",
                      attrs: { type: "text" },
                      on: { click: _vm.loadMore }
                    },
                    [_vm._v("Load more")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }