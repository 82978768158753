import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { Commits, Members } from "@/api/github";
import Timeline from "timeline-vuejs";
export default {
  name: "Test",
  components: {
    Timeline: Timeline
  },
  data: function data() {
    return {
      messageWhenNoItems: "There arent commits",
      members: [],
      dataTimeline: [],
      page: 0
    };
  },
  created: function created() {
    this.loadCommits();
    this.loadMembers();
  },
  methods: {
    loadMore: function loadMore() {
      this.page++;
      this.loadCommits();
    },
    loadCommits: function loadCommits() {
      var _this = this;

      Commits(this.page).then(function (_ref) {
        var data = _ref.data;
        data.forEach(function (element) {
          if (element.commit.message) {
            _this.dataTimeline.push({
              from: new Date(element.commit.author.date),
              title: element.commit.author.name,
              showDayAndMonth: true,
              description: "<a style=\"color: #26191b\" href=\"".concat(element.html_url, "\">").concat(element.commit.message, "</a>")
            });
          }
        });
      });
    },
    loadMembers: function loadMembers() {
      var _this2 = this;

      Members().then(function (_ref2) {
        var data = _ref2.data;
        _this2.members = data;

        _this2.members.sort();
      });
    }
  }
};